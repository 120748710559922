import React from "react";
import { Link } from "gatsby"
import i18n from '../i18n/i18n';
import { connect } from 'react-redux';
import '../assets/css/index.css'; // add some style if you want!

class Index extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      lang: this.props.lang,
      countryCode: ''
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      lang: nextProps.lang
    })
  }

  async componentDidMount() {
    await fetch('http://ip-api.com/json')
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ countryCode: responseJson.countryCode })
      })
      .catch((error) => {
      });
  }

  render() {
    // const data = this.props.data;
    // const { edges: posts } = data.allMarkdownRemark;
    let pathWeb = 'webr/' + this.props.location.search;
    let pathMobile = 'mobile/' + this.props.location.search;
    let pathFarmer = 'farmer/' + this.props.location.search;
    let pathEnterprise = 'enterprise/' + this.props.location.search;
    let listRS1 = null;
    let listRS2 = null;
    const { user } = this.props;

    if (this.state.lang === 'rs') {
      listRS1 = (
        <div>
          <li>Praćenje vozila preko naprednog GPS tracking sistema</li>
          <li>Praćenje i evidentrianje nastalih promena na parcelama kroz beleženje terenskih intervencija</li>
          <li>Upravljanje troškovima i resursima kroz set izveštaja</li>
          <li>Mogućnost integracije sa ERP sistemima</li>
        </div>
      )
      listRS2 = (
        <div>
          <li>Brza razmena informacija za osiguranje prinosa</li>
          <li>Market place za kupovinu/prodaju poljoprivrednih proizvoda i razmenu informacija između poljoprivrednih proizvođača</li>
        </div>
      )
    }
    return (
      <div className="index-page">
        <div className="index-1">
          <div className="link1">
            <Link to={pathMobile}>
              <img src={require("../assets/images/Mobile.png")} alt="AgroLIFE" />
              <h1>{i18n.t('Mobile_app')} </h1>
            </Link>
          </div>
          <div className="link1">
            <Link to={pathWeb}>
              <img src={require("../assets/images/Web.png")} alt="AgroLIFE" />
              <h1>{i18n.t('WEB_app')}</h1>
            </Link>
          </div>
          {this.state.countryCode != 'NG' &&
            < div className="link1">
              <Link to={pathFarmer}>
                <img src={require("../assets/images/Web.png")} alt="AgroLIFE" />
                <h1>{i18n.t('Farmer_app')} </h1>
              </Link>
            </div>
          }
          {this.state.countryCode != 'NG' && (!user || (user && user.id_klijent_grupa === 4)) ?
            <div className="link1">
              <Link to={pathEnterprise}>
                <img src={require("../assets/images/Web.png")} alt="AgroLIFE" />
                <h1>{i18n.t('Enterprise_app')}</h1>
              </Link>
            </div> : null
          }
        </div>
        <div className="index-2">
          <div className="naslov">
            <h1>{i18n.t('AGROLIFE_PLATFORM')}</h1>
          </div>
          <div className="podnaslovi">
            <div className="podnaslov-1">
              <h2>{i18n.t('CHALLENGES')}</h2>
              <ul>
                <li>{i18n.t('li1')}</li>
                <li>{i18n.t('li2')}</li>
                <li>{i18n.t('li3')}</li>
                <li>{i18n.t('li4')}</li>
                <li>{i18n.t('li5')}</li>
                {listRS1}
              </ul>
            </div>
            <div className="podnaslov-2">
              <h2>{i18n.t('VALUE_PROPOSITION')}</h2>
              <ul>
                <li>{i18n.t('li21')}</li>
                <li>{i18n.t('li22')}</li>
                <li>{i18n.t('li23')}</li>
                <li>{i18n.t('li24')}</li>
                <li>{i18n.t('li25')}</li>
                <li>{i18n.t('li26')}</li>
                <li>{i18n.t('li27')}</li>
                {listRS2}
              </ul>
            </div>
          </div>
          <div className="frame">
            <iframe width="90%" height="100%" src="https://www.youtube.com/embed/4_fMKyiusik" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
          </div>
        </div>

      </div >
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.appReducer.lang,
    user: state.appReducer.user
  };
}
function mapDispatchToProps(dispatch) {
  return {
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);

// export const pageQuery = graphql`
//   query IndexQuery {
//     allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___title] }) {
//       edges {
//         node {
//           excerpt(pruneLength: 250)
//           id
//           frontmatter {
//             title
//             path
//             lang
//           }
//         }
//       }
//     }
//   }
// `;